import * as xlsx from 'xlsx';
import { useSnackbar } from 'notistack';

const useDownloadExcel = ({ fileName = 'Reporte', sheetName = 'Datos' }) => {
  const { enqueueSnackbar } = useSnackbar();

  const downloadExcel = (data, columns) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      enqueueSnackbar('No hay datos para exportar', { variant: 'warning' });
      return;
    }

    const columnKeys = columns.map(col => col.key);
    const headers = columns.map(col => col.header);

    const filteredRows = data.map(row => {
      const newRow = {};
      columnKeys.forEach(key => {
        if (row.hasOwnProperty(key)) {
          newRow[key] = row[key]; 
        }
      });
      return newRow;
    });

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(filteredRows, { header: columnKeys });

    try {
      filteredRows.forEach((row, rowIndex) => {
        columnKeys.forEach((key, colIndex) => {
          const cellAddress = xlsx.utils.encode_cell({ r: rowIndex + 1, c: colIndex });
          if (worksheet[cellAddress] && typeof row[key] === 'number') {
            worksheet[cellAddress].t = 'n'; 
            worksheet[cellAddress].z = '"$"#,##0;[Red]-"$"#,##0'; 
          }
        });
      });

      xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
      
      xlsx.writeFile(workbook, `${fileName}.xlsx`);

      enqueueSnackbar('Excel descargado correctamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error al descargar el Excel', { variant: 'error' });
    }
  };

  return { downloadExcel };
};

export default useDownloadExcel;
