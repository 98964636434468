import { useSnackbar } from 'notistack';

const usePrepareDataForExcel = () => {
  const { enqueueSnackbar } = useSnackbar();

  const columnMap = {
    category: "Categoría",
    item: "Item",
    name: "Nombre",
    amountInvoiced: "Acumulado",
    amountProjected: "Proyectado",
    amount: "Total",
  };

  const prepareData = (rows, columns) => {
    if (!rows || !Array.isArray(rows) || rows.length === 0) {
      enqueueSnackbar("No hay datos para exportar", { variant: "warning" });
      return { data: [], columns: [] };
    }

    const filteredRows = rows.filter((row) => row.parentId?.slice(0,4) === "item");

    const rowsWithParent = filteredRows.map((row) => {
      const {parentId} = row;
      const itemRow = rows.find((r) => r.id === parentId);
      const groupRow = rows.find((r) => r.id === itemRow?.parentId);

      return {
        ...row,
        item: itemRow?.name,
        category: groupRow?.name,
      }
    });

    const parentColumns = [
      { columnName: "category" },
      { columnName: "item" },
    ]

    const columnsWithParents = parentColumns.concat(columns);

    const headers = columnsWithParents.map((col) => ({
      header: columnMap[col.columnName] || col.columnName,
      key: columnMap[col.columnName] || col.columnName, 
    }));

    const columnKeys = headers.map((col) => col.key);

    const formattedData = rowsWithParent.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((originalKey) => {
        const translatedKey = columnMap[originalKey] || originalKey;
        newRow[translatedKey] = row[originalKey] ?? "";
      });
      return newRow;
    });

    return { data: formattedData, columns: headers };
  };

  return { prepareData };
};

export default usePrepareDataForExcel;
