import React, { FC, ChangeEventHandler, useState, useEffect } from 'react';

import { Box, Grid, Paper, InputBase, InputAdornment, SvgIcon, Toolbar } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import Icon from '../../../../../shared/components/Icon/Icon';

import Typography from '../../../../../shared/components/Typography/Typography';
import Styles from '../../styles/ContractListToolbar.module.scss';
import ContractMenu from './ContractMenu';
import { Button } from '../../../../../shared/components/Button/Button';
import usePrepareDataForExcel from '../../../Core/customHooks/usePrepareDataForExcel';
import useDownloadExcel from '../../../../../shared/customHooks/useDownloadExcel';
import useColumnsAndRowsForContractsFlow from '../../../Core/customHooks/useColumnsAndRowsForContractsFlow';

interface IContractListToolbar {
  handleChangeSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangeView: (event: React.MouseEvent<HTMLElement>, newContractsView: string) => void;
  handleNewContract: () => void;
  contractsView: string;
  download: () => void;
}

const ContractListToolbar: FC<IContractListToolbar> = ({
  contractsView,
  handleChangeView,
  handleChangeSearch,
  handleNewContract,
  download,
}) => {
  const [anchorNewContractButton, setAnchorNewContractButton] = useState<null | HTMLElement>(null);

  const { rows, columns} = useColumnsAndRowsForContractsFlow();

  const { downloadExcel } = useDownloadExcel({ fileName: 'Flujo_de_caja_contratos', sheetName: 'Flujo de caja' });
  const { prepareData } = usePrepareDataForExcel();

  const handleNewContractClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorNewContractButton(event.currentTarget);
  };

  const handleCloseNewContractMenu = () => {
    setAnchorNewContractButton(null);
  };

  const handleDownload = () => {
    const { data, columns: preparedColumns } = prepareData(rows, columns);
    downloadExcel(data, preparedColumns);
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item>
              <Typography as="h1" variant="headline3">
                Contratos
              </Typography>
            </Grid>
            <Grid item>
              <Box display={contractsView === 'FC' ? 'none' : 'inherit'}>
                <Paper component="form" className={Styles.root}>
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                  <InputBase
                    className={Styles.input}
                    placeholder="Buscar"
                    inputProps={{ 'aria-label': 'Buscar contratos' }}
                    onChange={handleChangeSearch}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item>
              <Box display={contractsView === 'FC' ? 'inherit' : 'none'}>
                <Button
                  variant="outlined"
                  disableElevation
                  startIcon={<Icon variant="Download" color="primary600" />}
                  onClick={handleDownload}
                >
                  <Typography variant="subtitle1" color="primary600">
                    Descargar
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
              <Toolbar disableGutters variant="dense">
                <ToggleButtonGroup size="medium" value={contractsView} exclusive onChange={handleChangeView}>
                  <ToggleButton
                    value="detailed"
                    focusRipple
                    classes={{
                      root: Styles.toggleButton,
                      selected: Styles.toggleButtonSelected,
                    }}
                  >
                    <ViewListOutlinedIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton
                    value="FC"
                    focusRipple
                    classes={{
                      root: Styles.toggleButton,
                      selected: Styles.toggleButtonSelected,
                    }}
                  >
                    <AccountBalanceOutlinedIcon fontSize="small" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Toolbar>
            </Grid>
            <Grid item>
              <Button
                startIcon={<AddTwoToneIcon fontSize="small" />}
                onClick={handleNewContractClick}
                variant="contained"
              >
                Nuevo Contrato
              </Button>

              <ContractMenu
                anchorEl={anchorNewContractButton}
                handleClose={handleCloseNewContractMenu}
                handleNewContract={handleNewContract}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractListToolbar;
