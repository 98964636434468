import moment from 'moment';
import useContractDetail from '../../../ContractDetail/Core/customHooks/useContractDetail';
import { useEffect, useState } from 'react';

const useDateValidation = ({ data, currentContractId, minValidDate, maxValidDate }) => {
  const [paymentDate, setPaymentDate] = useState<string>('');
  const [isValidPeriod, setIsValidPeriod] = useState(null);
  const [initialPaymentDate, setInitialPaymentDate] = useState<number>();

  const { displacement, installments, paymentCycle } = data;

  const { currentContract } = useContractDetail(currentContractId);

  const normalizeDate = (date: any): moment.Moment => {
    if (moment.isMoment(date)) {
      return date;
    }

    if (typeof date === 'number' || typeof date === 'string') {
      const parsedDate = moment(date, [moment.ISO_8601, 'x', 'YYYY-MM-DD'], true);
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }

    throw new Error('Invalid date format');
  };

  const generatePaymentDates = (paymentDate: string): string[] => {
    const increment = (paymentDate: string, paymentCycle: string = 'MONTHLY', installments: number): moment.Moment => {
      const paymentDateMoment = normalizeDate(paymentDate).startOf('month');

      switch (paymentCycle) {
        case 'MONTHLY':
          return paymentDateMoment.clone().add(installments, 'months');
        case 'QUARTERLY':
          return paymentDateMoment.clone().add(installments * 3, 'months');
        case 'SEMIANNUALLY':
          return paymentDateMoment.clone().add(installments * 6, 'months');
        case 'YEARLY':
          return paymentDateMoment.clone().add(installments, 'years');
        default:
          throw new Error('Invalid payment cycle');
      }
    };

    const startDate = moment(Number(paymentDate)).add(displacement, 'months');

    let paymentDatesArray: string[] = [];

    const paymentDates = Array.from({ length: installments }).map((_, i) =>
      increment(startDate.startOf('month').format('x'), paymentCycle, i).format('YYYY-MM-DD'),
    );

    paymentDates.length > 0
      ? paymentDatesArray.push(...paymentDates)
      : paymentDatesArray.push(startDate.format('YYYY-MM-DD'));

    return paymentDatesArray;
  };

  const updatePaymentDate = (paymentDate: string) => {
    setPaymentDate(paymentDate);
  };

  const dateValidation = (paymentDate) => {
    updatePaymentDate(paymentDate);
    const finalDateOfContractPeriod = moment(Number(currentContract?.toDate || maxValidDate)).startOf('month');
    const initialDateOfContractPeriod = moment(Number(currentContract?.fromDate || minValidDate)).startOf('month');

    const paymentDates = generatePaymentDates(paymentDate);

    const lastPaymentDate = paymentDates[paymentDates.length - 1];

    const paymentDateMoment = normalizeDate(paymentDates[0]).startOf('month');

    paymentDateMoment && setInitialPaymentDate(moment(paymentDateMoment).valueOf());

    const lastPaymentDateMoment = normalizeDate(lastPaymentDate).startOf('month');

    const isValidPeriod =
      lastPaymentDateMoment.isSameOrBefore(finalDateOfContractPeriod) &&
      paymentDateMoment.isSameOrAfter(initialDateOfContractPeriod);

    // @ts-ignore
    setIsValidPeriod(isValidPeriod);
  };

  const onChangeData = () => {
    if (paymentDate) {
      dateValidation(paymentDate);
    }
  };

  useEffect(() => {
    onChangeData();
  }, [data, paymentDate, initialPaymentDate]);

  return {
    dateValidation,
    isValidPeriod,
    setIsValidPeriod,
    initialPaymentDate,
  };
};

export default useDateValidation;
